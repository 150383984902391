$(document).ready(function () {
    //console.log('scriptsmain.js doc.ready!');

    var isDesktop = $("[data-isdesktop]").data("isdesktop"),
        isNotMobile = $("[data-isnotmobile]").data("isnotmobile");

    function detectIE() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return -1;
    }

    if (navigator.userAgent.toLowerCase().indexOf("firefox") < 0) {
        $('.js-menu-drop-down').on('focus', function() {
            $(this).closest('.global-nav').addClass('js-prevent-close');
        });
        $('.js-menu-drop-down').on('blur', function() {
            $(this).closest('.global-nav').removeClass('js-prevent-close');
        });

        $('.global-nav__dropdown').on('mouseover', function() {
            $(this).find('.js-menu-drop-down').blur();
        });
    }


    //console.log('scriptmain.js **********************************************************************************');
    var acTimeout = null
    function timeRemovePreventClose(that) {
        if(acTimeout) clearTimeout(acTimeout);
        //console.log('.timeRemovePreventClose.start');
        acTimeout = setTimeout(function () {
            //console.log('.timeRemovePreventClose.done');
            clearTimeout(acTimeout);
            $(that).closest('.global-nav').removeClass('js-prevent-close');
        }, 3000);
    }

    $('.js-menu-drop-down-autocomplete').on('focus', function () {
        //console.log('.js-menu-drop-down-autocomplete.on.focus');
        $(this).closest('.global-nav').addClass('js-prevent-close');
    });
    $('.js-menu-drop-down-autocomplete').on('blur', function () {
        //console.log('.js-menu-drop-down-autocomplete.on.blur');
        $(this).closest('.global-nav').removeClass('js-prevent-close');
        //timeRemovePreventClose(this);
    });

    $('.global-nav__dropdown').on('mouseover', function () {
        $(this).find('.js-menu-drop-down-autocomplete').blur();
    });

    $('body').on("mouseover", '.js-autocomplete-item', function () {
        //console.log('.js-autocomplete-item.mouseover');
        clearTimeout(acTimeout);
        $(this).closest('.global-nav').addClass('js-prevent-close');
        
        setTimeout(function () {
            //console.log('.timeRemovePreventClose.done');
            $('.citiesRegions .js-dropdown').removeClass('hovered');
            $('.js-dropdown-cities').addClass('hovered');
        }, 100);
    });
    $('body').on("mouseout", '.js-autocomplete-item', function () {
        //console.log('.js-autocomplete-item.mouseout');
        $(this).closest('.global-nav').removeClass('js-prevent-close');
        //timeRemovePreventClose(this);
    });



    if ($('.js-directory-2023').length > 0) {
        //console.log('js-directory-2023.length:', $('.js-directory-2023').length);

        $('body').on("click", '.js-autocomplete-item', function () {
            //console.log('.js-autocomplete-item.click');
            $('.js-filter-form-submit').trigger( "click" );
        });

        //NOTE: StartDate & EndDate watchers are active within the filterdatepicker snippets on Wdot.TravelWisconsin.Web\JS\wdot\Directory.js for Events and Deals (get both).

        $('.js-directory-2023').on("change", '#City', function () {
            //console.log('#City.change');
            setTimeout(() => {
                $('.js-filter-form-submit').trigger( "click" );
            }, 300);
        });
        $('.js-directory-2023').on("change", '#RegionId', function () {
           // console.log('#RegionId.change');
            setTimeout(() => {
                $('.js-filter-form-submit').trigger( "click" );
            }, 300);
        });
        $('.js-directory-2023').on("change", '#category-id', function () {
            //console.log('#category-id.change');
            setTimeout(() => {
                $('.js-filter-form-submit').trigger( "click" );
            }, 300);
        });
        $('.js-directory-2023').on("change", '#mile-range', function () {
            //console.log('#mile-range.change');
            setTimeout(() => {
                $('.js-filter-form-submit').trigger( "click" );
            }, 300);
        });

    }


    if ($('.js-categories-slick').length > 0) {
       // console.log('scriptsmain: found .js-categories-slick INITIALIZE');
        tw.SlickSliders.initializeCategories();
    }

    if ($('.js-articles-slick').length > 0) {
        //console.log('scriptsmain: found .js-articles-slick INITIALIZE');
        tw.SlickSliders.initializeArticles();
    }

    if ($('.js-listing-page-slick').length > 0) {
        //console.log('scriptsmain: found .js-listing-page-slick INITIALIZE');
        tw.SlickSliders.initializeListingPage();
    }

    if ($('.js-media-sbs-slick').length > 0) {
        //console.log('scriptsmain: found .js-media-sbs-slick INITIALIZE');
        tw.SlickSliders.initializeMediaGallerySBS();
    }

    


    if (isDesktop) {
        if ($(".eventsDropdown .cityAutoComplete").length > 0) {
            //console.log('scriptsmain.js .eventsDropdown .cityAutoComplete');
            wdot.City.loadCityAutocompleteForPublicSiteMenu($(".eventsDropdown"), ".cityFilterAutoComplete");
        }
    }

    $(".reportIssue").click(function () {
        //console.log('reportIssue');
        var isFooter = false;

        if ($(this).hasClass('js-feedback-footer')) {
            isFooter = true;
        }
		
		var feedbackModalHeight = "auto";
		
		if (isDesktop === "True") {
			feedbackModalHeight = 600;
		}

        //$("body").append("<script type='text/javascript' src='//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js'>");
        Wdot.ModalHelpWindow.loadAjaxGet(400, feedbackModalHeight, true, '/UserFeedback?referenceURL=' + window.location + '&isFooter=' + isFooter, function () {
            // var playerVersion = swfobject.getFlashPlayerVersion();
            // var version = playerVersion.major + "." + playerVersion.minor + "." + playerVersion.release;
            // document.getElementById("FlashVersion").value = version;
            // document.getElementById("ScreenHeight").value = screen.height;
            // document.getElementById("ScreenWidth").value = screen.width;
            $("#Name").focus();

            $('#UserFeedbackItemId').val($('#baseContentId').val());
            
            if ($('#feedbackInfo').length > 0) {
                $('#UserFeedbackItemId').val($('#feedbackInfo').attr("data-id"));
                $('#UserFeedbackIsPlace').val($('#feedbackInfo').attr("data-isPlace"));
            } else {
                $('#UserFeedbackItemId').val($('.js-feedback-footer').attr("data-content-id"));
                $('#UserFeedbackIsContentPage').val(true);
                $('#UserFeedbackIsPlace').val(false);
            }
        }, null, 'modal fixed');



    });

    $('a.listingToggle.mediaRoomToogle').unbind();
    // Expandable List 
    $('a.listingToggle.mediaRoomToogle').click(function (e) {
        e.preventDefault(); // Disable link functionality
        // Basic accordion functionality
        if ($(this).siblings('ul').hasClass('opened')) {
            // Do this if it clicking on an open item
            $(this).siblings('ul').slideToggle("slow").toggleClass('opened');
            $(this).removeClass('sectionOpen').parent().removeClass('active');
        } else {
            // Do this if clicked item was closed
            $('a.listingToggle.mediaRoomToogle')
                .removeClass('sectionOpen')
                .siblings('ul.opened').slideToggle("slow").toggleClass('opened');
            $(this).siblings('ul').slideToggle("slow").toggleClass('opened');
            $('.topLevel').removeClass("active");
            $(this).addClass('sectionOpen').parent().addClass('active');
        }

        return false;
    });

    $(".myTripsUtility").on("click", ".deleteBtn", function () {
        var self = this;
        if (confirm("Delete Trip?")) {
            $.ajax({
                url: '/trip-planner/deletetrip',
                contentType: 'application/json',
                data: JSON.stringify({ tripId: $(self).attr("data-id") }),
                dataType: 'json',
                type: 'POST'
            }).done(function (data) {
                if (data.Success) {
                    if ($(self).siblings(".selectBtn").hasClass("active")) {
                        $($(".myTrips .selectBtn")[0]).click();
                    }
                    $('.myTrips .deleteBtn[data-id=' + $(self).attr("data-id") + '],.myTripsUtility .deleteBtn[data-id=' + $(self).attr("data-id") + ']').parent().remove();
                    wdot.TripPlanner.ResetTripItems();
                    $($(".myTrips .changeTrip")[0]).click();
                } else {
                    alert(data.ErrorMessage);
                }
            });
        }
    });

    $(".myTripsUtility").on("click", ".selectBtn", function () {
        var self = this;
        $.ajax({
            url: '/trip-planner/activatetrip',
            contentType: 'application/json',
            data: JSON.stringify({ tripId: $(self).attr("data-id") }),
            dataType: 'json',
            type: 'POST'
        }).done(function (data) {
            if (data.Success) {
                $(".myTrips .selectBtn, .myTripsUtility .selectBtn").removeClass("active");
                var activeTrip = $('.myTrips .selectBtn[data-id=' + $(self).attr("data-id") + '],.myTripsUtility .selectBtn[data-id=' + $(self).attr("data-id") + ']');
                activeTrip.addClass("active");
                var name = activeTrip.siblings(".navigateToTrip").html();
                var guid = activeTrip.siblings(".navigateToTrip").attr("data-guid");
                $(".userNav .tripPlanner #tripName").text(name);
                $(".userNav .tripPlanner #tripName").attr("data-guid", guid);
            } else {
                alert(data.ErrorMessage);
            }
        });
    });

    $(".myTripsUtility").on("click", ".createTrip", function () {
        $.ajax({
            url: '/trip-planner/createtrip',
            type: 'POST',
            contentType: 'application/json'
        }).done(function (data) {
            if (data.Success) {
                var myTripUtility = "<li><a href='/trip-planner/?guid=" + data.Trip.Guid + "' class='navigateToTrip' data-guid='" + data.Trip.Guid + "'>" + data.Trip.Name + "</a>";
                var myTrips = "<li><a href='javascript:void(0)' class='changeTrip' data-guid='" + data.Trip.Guid + "'>" + data.Trip.Name + "</a>";
                var myTripCommon = "<a href='javascript:void(0)' class='btn deleteBtn' data-id='" + data.Trip.Id + "'>";
                myTripCommon += "<div class='iconFont' data-icon='('></div>";
                myTripCommon += "<span class='visuallyhidden'>Delete this Trip</span></a>";
                myTripCommon += "<a href='javascript:void(0)' class='btn selectBtn' data-id='" + data.Trip.Id + "'><div class='circle'><span class='visuallyhidden'>Select this Trip</span></div></a></li>";
                $("ul.myTripsUtility li.last").before(myTripUtility + myTripCommon);
                $(".myTrips ul").append(myTrips + myTripCommon);
                window.location = '/trip-planner/?guid=' + data.Trip.Guid;
            } else {
                alert(data.ErrorMessage);
            }
        });
    });

    $(".contentPageDirectoryInfoShare").click(function() {
        $('html, body').animate({
            scrollTop: $(".social-sharing__container").offset().top - 100
        }, 500); 
    });

    $("body").on("click", ".addToTrip:not(.addToTrip-Planner)", function () {
        var item = $("#feedbackInfo");

        if (item.length == 0 || item.attr('data-id') == undefined) {
            item = $(this).parents("li");
        }

        var self = this;

        if ($(self).find(".addTripPopup").length == 0) {
            $.ajax({
                url: '/trip-planner/getaddtrippopup?isplace=' + item.attr("data-isPlace") + '&idtoadd=' + item.attr("data-id"),
                type: 'GET',
                contentType: 'application/json'
            }).done(function (data) {
                if (!data.IsNotLoggedIn) {
                    $(self).after(data);
                } else if (data.IsNotLoggedIn) {
                    var location = window.location.toString();
                    window.location = '/account/logon?redirecturl=' + escape(location);
                }
            });
        }
    });

    $("body").on("click", ".editTrip", function () {
        $.ajax({
            url: '/trip-planner/gettrip',
            contentType: 'application/json',
            data: JSON.stringify({ tripGuid: $("#SelectedTrip option:selected").attr("data-guid") }),
            dataType: 'json',
            type: 'POST'
        }).done(function (data) {
            if (data.Success) {
                wdot.TripPlanner.LoadPlannerOnFlyoutLoad = false;
                wdot.TripPlanner.Planner.PreLoadTrip = data.Trip;
                wdot.TripPlanner.ActivatePlannerForEdit();
            }
        });
    });

    $("body").on("click", ".createTrip", function () {
        if ($(this).parents(".myTrips").length == 0){
            wdot.TripPlanner.LoadPlannerOnFlyoutLoad = false;
            wdot.TripPlanner.Planner.CreateTrip($(this).attr("data-idToAdd"), $(this).attr("data-isPlaceId"), null,true);
        }
    });

    $("body").on("click", ".addToTripBtn", function () {
        var isPlace, placeOrEventId;
        var item = $("#feedbackInfo");

        if (item.length == 0 || item.attr('data-id') == undefined) {
            item = $(this).parents("li");
        }

        isPlace = item.attr("data-isPlace");
        placeOrEventId = item.attr("data-id");

        if (isPlace == undefined || placeOrEventId == undefined) {
            var dataDiv = $(this).parents(".addTripPopup").siblings(".searchAddToTrip").children("div[data-contenttype]");
            isPlace = dataDiv.attr("data-contenttype") == "Place" ? "true" : "false";
            placeOrEventId = dataDiv.attr("data-contentid");
        }

        var self = this;

        if ($(self).find(".addTripPopup").length == 0 && isPlace != undefined && placeOrEventId != undefined) {
            $.ajax({
                url: '/trip-planner/addtripsegmentpoint?isplace=' + isPlace + '&placeoreventid=' + placeOrEventId + '&displayorder=0&tripId=' + $("#SelectedTrip").val(),
                type: 'GET',
                contentType: 'application/json'
            }).done(function (data) {
                if (data.Success) {
                    $(".addingToTrip").addClass("hidden");
                    $(".successfullyAddedTrip").removeClass("hidden");
                } else {
                    alert(data.ErrorMessage);
                }
            });
        } else {
            alert('Error occured trying to add item to trip');
        }
    });

    $("body").on("click", ".closePopup", function () {
        $(".addTripPopup").remove();
    });

    $("body").on("click", ".removeFavorite", function () {
        wdot.Favorites.RemoveFavorite(this);
    });

    $("a.marqueeLink").click(function () {
        var that = this;
        var text = $(that).find('img').attr('alt');
        _gaq.push(['_trackEvent', 'Marquee', 'Category Image', text]);
        _gaq.push(function () {
            window.location = $(that).attr('href');
        });
        //return false;

        return !window._gat; // Ensure that the event is bubbled if GA is not loaded
    });

     //jk-commented-out-for-upgrade
    // Modernizr.load({
    //     test: Modernizr.backgroundsize,
    //     nope: ['/js/roughedge-ie.js']
    // });
    // Modernizr.load({
    //     test: Modernizr.fontface,
    //     nope: ['/js/typeface-0.15.js', '/js/icomoon_regular.typeface.js']
    // });
     //jk-commented-out-for-upgrade

    if (isNotMobile) {
        $(".calloutAdLink").click(function() {
            var _this = this; // The form input element that was just clicked
            //_gaq.push([
            //    '_set', 'hitCallback', function() {return true;}
            //]);
            _gaq.push(['_trackEvent', 'Promo', 'Side', $(_this).find('img').attr('alt')]);
        });
    }

    if ($(".sojern").length) {
        (function() {
            var params = {};

            /* Please do not modify the below code. */
            var cid = [];
            var paramsArr = [];
            var cidParams = [];
            var pl = document.createElement('script');
            var defaultParams = { "vid": "tou" };
            for (key in defaultParams) {
                params[key] = defaultParams[key];
            };
            for (key in cidParams) {
                cid.push(params[cidParams[key]]);
            };
            params.cid = cid.join('|');
            for (key in params) {
                paramsArr.push(key + '=' + encodeURIComponent(params[key]))
            };
            pl.type = 'text/javascript';
            pl.async = true;
            pl.src = 'https://beacon.sojern.com/pixel/p/23141?f_v=v6_js&p_v=1&' + paramsArr.join('&');
            (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);
        })();
    }

    // $('.js-stickyfoot__close').click(function () {
    //     $('#js-stickyfoot').hide();
    //     $.cookie('HideStickyFooter', 'true');
    // });


});

(function () {
    if (typeof window.janrain !== 'object') window.janrain = {};
    if (typeof window.janrain.settings !== 'object') window.janrain.settings = {};

    janrain.settings.tokenUrl = $("[data-baseurl]").data("baseurl") + '/account/sociallogin';
    janrain.settings.custom = true;
    janrain.settings.type = 'embed';
    janrain.settings.appId = 'aeplkjnlaahlecefjkcn';
    janrain.settings.appUrl = 'https://wdtourism.rpxnow.com';
    janrain.settings.providers = ["facebook", "google", "twitter"];
    janrain.settings.providersPerPage = '4';
    janrain.settings.format = 'one row';
    janrain.settings.actionText = 'Login';
    janrain.settings.showAttribution = false;
    janrain.settings.fontColor = '#333333';
    janrain.settings.fontFamily = 'arial';
    janrain.settings.backgroundColor = '#FFFFFF';
    janrain.settings.width = '147';
    janrain.settings.borderColor = '#ffffff';
    janrain.settings.borderRadius = '0'; janrain.settings.buttonBorderColor = '#CCCCCC';
    janrain.settings.buttonBorderRadius = '5';
    janrain.settings.buttonBackgroundStyle = 'gradient';
    janrain.settings.language = '';
    janrain.settings.linkClass = 'janrainEngage';

    function isReady() { janrain.ready = true; };
    if (document.addEventListener) {
        document.addEventListener("DOMContentLoaded", isReady, false);
    } else {
        window.attachEvent('onload', isReady);
    }

    var e = document.createElement('script');
    e.type = 'text/javascript';
    e.id = 'janrainAuthWidget';

    if (document.location.protocol === 'https:') {
        e.src = 'https://rpxnow.com/js/lib/wdtourism/engage.js';
    } else {
        e.src = 'http://widget-cdn.rpxnow.com/js/lib/wdtourism/engage.js';
    }

    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(e, s);

    //User Feedback Modal
    $('body').on('click', '.js-show-section', function () {
        var form = $('#userFeedBack');
        //console.log('form.serialize():', form.serialize());
        Wdot.Ajax.prototype.Post('/UserFeedback/PopulateFormAjax', form.serialize(), function (data) {
            $('.js-feedback-content').html(data);
        }, 'html');
    });

    //Hacky work around to fix a flickering bug with the date/time picker plugins
    $('body').on('focus', '.js-feedback-filterdatepicker', function () {

        $('.js-feedback-filterdatepicker').datepicker('destroy');
        $('.js-feedback-filtertimepicker').datepicker('destroy');

        var self = $(this);

        setTimeout(function () {
            self.datepicker();
            self.datepicker("show");
        }, 10);

    });

    //$('body').on('focus', '.js-feedback-filtertimepicker', function () {

    //    $('.js-feedback-filterdatepicker').datepicker('destroy');
    //    $('.js-feedback-filtertimepicker').datepicker('destroy');
    //    var self = $(this);

    //    setTimeout(function() {
    //        self.timepicker({
    //            ampm: true,
    //            hourGrid: 4,
    //            minuteGrid: 15
    //        });
    //        self.timepicker("show");
    //    }, 10);

    //});

    //$('#eventsMenuForm .datepicker').datepicker();






 
    $('body').on('click', '.js-close-dialog', function(e) {
        e.preventDefault();
        $(this).closest('#dialog').dialog('close');
    });
})();

/* window.onload = function() {
    var detective = new Detector();

    if (detective.detect('icomoon')) {
        $('.iconFont').removeClass('foutFix');
    } else {
        var tryCount = 0;
        var timeoutFunctionForIcon = function() {
            var detective = new Detector();

            if (detective.detect('icomoon') || tryCount == 4) {
                $('.iconFont').removeClass('foutFix');
                $(".iconReplacementLogo").hide();
                timeout = null;
            } else {
                timeout = setTimeout(timeoutFunctionForIcon, 500);
            }

            tryCount++;
        }
        var timeout = setTimeout(timeoutFunctionForIcon, 500);
    }
}; */


var janrainShareOnload = function () {
    var facebookButton = document.getElemenenById("facebook");
    facebookButton.onclick = function() {
        jainrain.engage.loginAndSend();
    }; 
}

var isDesktop = $("[data-isdesktop]").data("isdesktop");